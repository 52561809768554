@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Montserrat&family=Playfair+Display&family=Poppins&family=Sacramento&family=Urbanist&display=swap');
*{
  margin: 0px;
}
.desktop{
  position: relative;
width: 1366px;
height: 947px;

background: #FFFFFF;
}
.headerr{
  position: absolute;
  width: 1366px;
  height: 73.03px;
  left: 0px;
  top: 0px;
  
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
}
.navbar{
  position: absolute;
  height: 73.03px;
  left: 0px;
  right: 0px;
  top: 0px;
  
  background: #FFFFFF;
}
.heroImage
{
  position: absolute;
width: 1366px;
height: 73px;
left: 0px;
top: 0px;

}

.logo{
  position: absolute;
width: 160px;
height: 63px;
left: 74px;
top: 6px;
}
.tudeDude{
  position: absolute;
width: 160px;
height: 63px;

background: url(./images/TuteDude.png);
}

.nav{
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 28px;

position: absolute;
width: 519px;
height: 24px;
left: 766px;
top: 25px;
}
.assignment{
  margin: 0px; 

  width: 140px;
height: 22px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 123.5%;
/* or 22px */

text-align: center;

color: rgba(128, 0, 128, 0.25);
}
.chat{
  margin: 0px; 
  width: 156px;
height: 22px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 123.5%;
/* or 22px */

text-align: center;

color: rgba(128, 0, 128, 0.25);


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}
.profile{
  display: flex;
flex-direction: row;
align-items: flex-end;
padding: 0px;
gap: 4px;

width: 167px;
height: 24px;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
}

.profileImage{
  position: absolute;

margin-top: 2px;
margin-left: 2px;
  width: 20px;
  height: 20px;

/* Inside auto layout */



}
.profileImageView{
  width: 24px;
height: 24px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}


.profileName{
  width: 111px;
height: 22px;
margin: 0px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 123.5%;
/* or 22px */

text-align: center;

color: #800080;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}
.keyarrow{
  width: 24px;
height: 24px;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
}
.arrow{
  position: absolute;
  width: 12px;
  height: 7.41px;
  margin-top: 8px;
  margin-left: 6px;;

}
.referAndEarn{
  position: absolute;
width: 1366px;
height: 76px;
left: 0px;
top: 73px;

background: #FFFFFF;
} 
.referAndEarnText{
  position: absolute;
  width: 138px;
  height: 21px;
  left: 187px;
top: 27px;
  
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  
  
  color: #000000;
}
.dashboardView{
  position: absolute;
width: 486px;
height: 188px;
left: 187px;
top: 179px;

background: #FFFFFF;
box-shadow: 0px 3px 100px rgba(0, 0, 0, 0.1);
border-radius: 20px;
}
.earningWrapper{
  display: flex;
flex-direction: column;
align-items: center;
padding: 0px;

position: absolute;
width: 116px;
height: 69px;
left: 20px;
top: 20px;
}
.referralEarning{
  width: 111px;
height: 21px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height */


color: #800080;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.referralEarningMrp{
  width: 116px;
height: 48px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
/* identical to box height */


color: #000000;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}
.totalReferralsWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  
  position: absolute;
  width: 100px;
  height: 69px;
  left: 198px;
  top: 20px;
  
}
.totalReferrals{
  width: 100px;
  height: 21px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  
  
  color: #800080;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
}
.totalReferralsInNumber{
  width: 18px;
height: 48px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
/* identical to box height */


color: #000000;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.walletBalanceWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  
  position: absolute;
  width: 105px;
  height: 69px;
  left: 361px;
  top: 20px;
}
.walletBalance{
  width: 105px;
  height: 21px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  
  
  color: #800080;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
}
.walletBalanceInNumber{
  width: 90px;
  height: 48px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */
  
  
  color: #000000;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 1;
  flex-grow: 0;
}
.withdrawBalanceWrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 10px;
  
  position: absolute;
  width: 180px;
  height: 40px;
  left: 153px;
  top: 119px;
  
  background: #800080;
  border-radius: 20px;
}
.withdrawBalance{
  width: 148px;
  height: 24px;
  padding: 7px 17px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  
  
  color: #FFFFFF;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
}
.yourRefferCode{
  position: absolute;
width: 228px;
height: 36px;
left: 766px;
top: 179px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
/* identical to box height */


color: #800080;
}
.yourRefferCodeWrapper{
  box-sizing: border-box;

  position: absolute;
  width: 358px;
  height: 60px;
  left: 766px;
  top: 224px;
  border-bottom-left-radius: 1%;
  border-bottom-color: green;
  border: 1.5px solid ;
  border-image: linear-gradient(45deg,rgb(128, 0, 128),rgb(255, 134, 76)) 1;
  border-radius: 10px;
}
.RefferCode{
  position: absolute;
  width: 230px;
  height: 30px;
  left: 64px;
  top: 12px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  
  letter-spacing: 1.5em;
  
  color: #800080;
}
.howDoesIttWork{
  position: absolute;
width: 224px;
height: 36px;
left: 187px;
top: 407px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
/* identical to box height */


color: #800080;

}
.inviteYourFriendWrapper{
  position: absolute;
width: 355.56px;
height: 72px;
left: 189px;
top: 463px;

  
}
.contentText{
  position: absolute;
width: 270.56px;
height: 48px;
left: 10px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;

color: #4D4D4D;

}
.contentText2{
  position: absolute;
  width: 274px;
  height: 48px;
  left: 10px;
  top: 25px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  
  color: #4D4D4D;
  
  
}
.referrelMoneyWrapper{
  position: absolute;
  width: 359.17px;
  height: 72px;
  left: 187px;
  top: 565px;
  
  
}
.transferMoneyWrapper{
  position: absolute;
  width: 359px;
  height: 72px;
  left: 187px;
  top: 667px;
  
}


.imageWrapper{
  position: absolute;
  width: 65px;
  height: 65px;
  left: 10px;
  top: 3px;
  border-radius: 50%;
  
  background: rgba(217, 217, 217, 0.25);
}
.imageWrapper2{
 
position: absolute;
left: 0%;
right: 0%;
top: 25%;
bottom: 25%;
}
.inviteimage{
  position: absolute;
  width: 32px;
  height: 16px;

left: 17px;
top: 23px;

}

.referrelimage{
  position: absolute;
  width: 22px;
  height: 30px;
  left: 22px;
  top: 16px;
}
.transferimage{
  position: absolute;
  width: 25px;
  height: 24px;
  left: 20px;
  top: 20px;
}
.courseimage{
  position: absolute;
  width: 26.67px;
  height: 26.67px;
  left: 20px;
  top: 20px;


}

.offerimage{
  position: absolute;
  width: 26.63px;
  height: 26.64px;
  left: 20px;
  top: 20px;
}
.contentWrapper{
  position: absolute;
  width: 274px;
  height: 72px;;
left: 85px;

}
.courseWrapper{
  position: absolute;
  width: 353.76px;
  height: 72px;
  left: 592px;
  top: 463px;
}
.offerWrapper{
  position: absolute;
  width: 359px;
  height: 72px;
  left: 592px;
  top: 565px;
  
}
.friendWhoEnroll{
  position: absolute;
  width: 168px;
  height: 24px;
  left: 187px;
  top: 799px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  
  
  color: #800080;
}
.termAndCondition{
  position: absolute;
width: 159px;
height: 24px;
left: 187px;
top: 843px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #800080;
}